import { BlockType } from '..';

const Items: Record<string, () => BlockType> = {
	page: () => require('./page/index').default,
	'calendar-item': () => require('./calendar-item/index').default,
	'gallery-item': () => require('./gallery-item/index').default,
	'gallery-items': () => require('./gallery-items/index').default,
	'gallery-view': () => require('./gallery-view/index').default,
	'gallery-container': () => require('./gallery-container/index').default,
	'gallery-current': () => require('./gallery-current/index').default,
	'google-search': () => require('./google-search/index').default,
	'search-box': () => require('./search-box/index').default,
	'google-results': () => require('./google-results/index').default,
	'calendly-widget': () => require('./calendly-widget/index').default,
	'disqus-widget': () => require('./disqus-widget/index').default,
	'google-maps': () => require('./google-maps/index').default,
	'add-event': () => require('./add-event/index').default,
	'filter': () => require('./filter/index').default,
	'filter-select': () => require('./filter-select/index').default,
	'filter-select-item': () => require('./filter-select-item/index').default,
	'dialog-container': () => require('./dialog-container/index').default,
	'dialog-view': () => require('./dialog-view/index').default,
	'paginator-container': () => require('./paginator-container/index').default,
	'paginated-list': () => require('./paginated-list/index').default,
	'page-list': () => require('./page-list/index').default,
	'hosted-video': () => require('./hosted-video/index').default,
	'date-range': () => require('./date-range/index').default,
	'date-range-container': () => require('./date-range-container/index').default,
	'toggle': () => require('./toggle/index').default
}

let sbt: typeof import('./content-related-list').subBlockTypes;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	Items['content-group'] = () => require('./content-group').default;
	Items['content-group-list'] = () => require('./content-group-list').default;
	Items['content-item'] = () => require('./content-item').default;
	Items['content-item-list'] = () => require('./content-item-list').default;
	Items['content-image-list'] = () => require('./content-image-list').default;
	sbt = require('./content-related-list').subBlockTypes;
}

export default Items;
export { sbt as GenerateSpecialBlockTypes }
