import React, { useEffect, useState, useCallback, useRef } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '../../components/DialogActions';
import DialogContent from '../../components/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '../../components/DialogTitle';
import Typography from '../../components/Typography';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ReactGA from 'react-ga4';
import ShareFabProps from './props';
import { InputAdornment } from '@material-ui/core';

declare namespace global {
	const _gaq: { push(params: any[]): void };
}

let open = (url: string) => {
	let left = (screen.width / 2) - (600 / 2);
	let top = (screen.height / 2) - (500 / 2);
	window.open(url, '_blank', `width=600,height=500,scrollbars=yes,chrome=yes,centerscreen,left=${left},top=${top}`);
}

let facebookShare = () => {
	if (typeof global._gaq == 'object' && global._gaq) global._gaq.push([ '_trackSocial', 'facebook', 'share', location.href ]);
	open(`http://www.facebook.com/sharer.php?u=${encodeURIComponent(location.href)}&t=${encodeURIComponent(document.title)}`);
}

let twitterShare = () => {
	open(`https://twitter.com/share?url=${encodeURIComponent(location.href)}&text=${encodeURIComponent(document.title.replace(/\|/g, '-'))}`);
}

let linkedInShare = () => {
	open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(location.href)}&title=${encodeURIComponent(document.title)}`);
}

type ShareDialogProps = ShareFabProps & {
	open: boolean;
	close: React.MouseEventHandler;
}

const ShareDialog = (props: ShareDialogProps) => {
	let [ url, setUrl ] = useState<string>(null);
	let [ shortUrl, setShortUrl ] = useState<string>(null);

	useEffect(() => {
		setUrl(location.href);
		setShortUrl(location.href);
	}, [ typeof location === 'undefined' ? '' : location.href ]);

	useEffect(() => {
		if (props.open && url) {
			(async () => {
				let t = await fetch(props.mountPath + '/shorten?url=' + encodeURIComponent(url));
				let j = await t.json() as { href: string };
				setShortUrl(j.href);
			})();
		}
	}, [props.open, url]);

	useEffect(() => {
		if (props.open) {
			let path = [''].concat(window.location.pathname.split('/').filter(v => !!v)).concat('share').join('/');
			ReactGA.send({ hitType: 'modalview', page: path });
		}
	}, [props.open]);

	let [copied, setCopied] = useState(false);
	let fieldRef = useRef<HTMLInputElement>(null);
	let copy = useCallback(() => {
		let oSel = window.getSelection();
		let ranges = [];
		for (let ri = 0; ri < oSel.rangeCount; ri++) {
			let range = oSel.getRangeAt(ri);
			ranges.push(range.cloneRange());
		}
		oSel.empty();
		fieldRef.current.select();
		document.execCommand('Copy');
		oSel.empty();
		for (let range of ranges) {
			oSel.addRange(range);
		}
		setCopied(true);
	}, [])
	return (
		<Dialog open={props.open} onClose={props.close} aria-labelledby='share-title' aria-describedby='share-description' maxWidth='sm' fullWidth className={props.classes.shareDialog}>
			<DialogTitle id='share-title' variant='h5' className={props.classes.shareTitle} onClose={props.close}>
				Share This Page
			</DialogTitle>
			<DialogContent className={props.classes.shareContent}>
				<Typography id='share-description' className={props.classes.shareDescription} variant="h5">Share on a social network</Typography>
				<div className={props.classes.shareButtons}>
					<IconButton className={props.classes.shareButton} onClick={facebookShare}>
						<FacebookIcon className={props.classes.icon}/>
					</IconButton>
					<IconButton className={props.classes.shareButton} onClick={twitterShare}>
						<TwitterIcon className={props.classes.icon}/>
					</IconButton>
					<IconButton className={props.classes.shareButton} onClick={linkedInShare}>
						<LinkedInIcon className={props.classes.icon}/>
					</IconButton>
				</div>
				<Typography id='share-copy' className={props.classes.shareCopy} variant='h5'>
					Copy a link to this page
				</Typography>
				<TextField {...{readOnly: true}} variant='outlined' aria-title='Link to this page' value={shortUrl} className={props.classes.link}
					InputProps={{
						endAdornment: <InputAdornment position='end' onClick={copy} aria-title='Copy' style={{ cursor: 'pointer', order: 2 }} aria-role='button'>
							<Icon>content_copy</Icon>
						</InputAdornment>
					}}					
					inputRef={fieldRef}
				/>
				{copied && (
					<Typography id='share-copied' className={props.classes.shareCopied} variant='h6'>
						Link has been copied to your clipboard
					</Typography>
				)}
			</DialogContent>
			<DialogActions onClose={props.close}/>
		</Dialog>
	);
}

const FabBlock = (props: ShareFabProps, ref: React.Ref<any>) => {
	let [ open, setOpen ] = useState(false);
	let toggle = useCallback(() => setOpen(!open), [open]);

	return (
		<>
			<div className={props.classes.root}>
				<div className={clsx('mui-fixed', props.classes.fabWrapper)}>
					<Fab color="primary" aria-label="Share" className={props.classes.fab} onClick={toggle}>
						<Icon>share</Icon>
					</Fab>
				</div>
			</div>
			<ShareDialog {...props} open={open} close={toggle}/>
		</>
	)
};

export default ThemeLoader(
	StyleLoader(
		FabBlock, 
		makeStyles(theme => ({
			fabWrapper: {
				position: 'fixed',
				bottom: theme.spacing(2),
				right: theme.spacing(2),
				zIndex: 10
			},
			fab: {},
			root: {
				height: 56 + theme.spacing(4)
			},
			icon: {
				fontSize: '4rem'
			},
			shareDialog: {},
			shareTitle: {},
			shareContent: {},
			shareDescription: {
				textAlign: 'center'
			},
			shareCopy: {
				textAlign: 'center',
				marginTop: theme.spacing(6),
				marginBottom: theme.spacing(2),
			},
			shareCopied: {
				color: theme.palette.success.main,
				textAlign: 'center',
				marginTop: theme.spacing(2),
			},
			shareButtons: {
				margin: theme.spacing(2, 0),
				justifyContent: 'center',
				display: 'flex',
				flexDirection: 'row'
			},
			shareButton: {
				margin: theme.spacing(0, 1.5),
				flex: '0 0',
				padding: 0,
				'& .MuiIconButton-label': {
					border: `1px solid ${theme.palette.type == 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
					borderRadius: '8px',
					padding: `8px`
				}
			},
			link: {
				display: 'flex',
				'& .MuiInputBase-root': {
					'&:after': {
						content: '""',
						height: 'calc(1.1875em + 18.5px + 18.5px)',
						display: 'block',
						order: 0,
						borderRadius: '4px 0 0 4px',
						width: '8px',
						borderLeft: `1px solid ${theme.palette.type == 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`
					}
				}
			}
		}), { name: 'Fab' })
	)
);
